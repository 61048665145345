<template>
  <div>
    <b-row v-if="historyList.length > 0">
      <b-col cols="12">
        <b-card class="my-card m-0">
          <b-table
            @row-contextmenu="handleContextMenuEvent"
            @row-clicked="clicked"
            striped
            small
            id="myTables"
            bordered
            selectable
            selected-variant="primary"
            select-mode="single"
            responsive="sm"
            :items="historyList"
            :fields="fields"
            head-row-variant="secondary"
          >
            <template #cell(checkbox)="row">
              <b-form-checkbox
                v-model="row.detailsShowing"
                plain
                class="vs-checkbox-con"
                @change="row.toggleDetails"
              >
              </b-form-checkbox>
            </template>
            <template #cell(billsec)="data">
              {{
                parseInt(data.item.billsec / 60) +
                ":" +
                (data.item.billsec % 60 < 10
                  ? "0" + (data.item.billsec % 60)
                  : data.item.billsec % 60)
              }}
            </template>
            <template #cell(performer_id)="data">
              <div
                @click="getDrivers(data)"
                v-if="data.item.performer_id"
                style="color: blue"
              >
                {{ data.item.performer_id }}
              </div>
            </template>
            <template #cell(order_id)="data">
              <div @click="getOrderHistory(data)" style="color: blue">
                {{ data.item.order_id }}
              </div>
            </template>
          </b-table>
          <vue-context ref="menu">
            <li>
              <b-link @click="call()" class="d-flex align-items-center">
                <feather-icon icon="PhoneOutgoingIcon" size="16" />
                <span class="ml-75">Позвонить</span>
              </b-link>
              <b-link @click="getAudio()" class="d-flex align-items-center">
                <feather-icon icon="PlayIcon" size="16" />
                <span class="ml-75">Прослушать</span>
              </b-link>
            </li>
          </vue-context>
        </b-card>
      </b-col>
    </b-row>
    <modal-audio :audioSrc="audioSrc"></modal-audio>
  </div>
</template>

<script>
import VueContext from "vue-context";
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
import { cellSelect } from "@/utils/TableFunctions/cellSelect";
import "bootstrap/dist/js/bootstrap.bundle";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "bootstrap/dist/css/bootstrap.css";
import modalAudio from "@/views/component/Modal/ModalSIP/modalAudio.vue";

export default {
  components: {
    VueContext,
    ToastificationContent,
    modalAudio,
  },
  data() {
    return {
      openCar: false,
      fields: [
        { key: "checkbox", label: "", thStyle: { width: "30px" } },
        { key: "id", label: "ID", sortable: true, thStyle: { width: "40px" } },
        {
          key: "uniqueid",
          label: "UID",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "calldate",
          label: "Дата звонка",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "disposition",
          label: "Статус",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "src",
          label: "Кто звонил",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "dst",
          label: "Куда звонил",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "billsec",
          label: "Длительность",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "performer_id",
          label: "Исполнитель",
          sortable: true,
          thStyle: { width: "88px" },
        },
        {
          key: "order_id",
          label: "ID заказа",
          sortable: true,
          thStyle: { width: "70px" },
        },
        {
          key: "driver_profile_id",
          label: "ID анкетты",
          sortable: true,
          thStyle: { width: "75px" },
        },
        {
          key: "memo_id",
          label: "ID СЗ",
          sortable: true,
          thStyle: { width: "45px" },
        },
        // {key: 'linkedid', label: 'Запись', sortable: true, thStyle: { width: '500px' }},
      ],
      selectedPhone: null,
      audioSrc: null,
      historyList: [],
    };
  },
  mounted() {
    resizeable();
    this.$http
      .get(
        `asterisk-api/history-call?filter_order_id=${this.$route.query.filter}`
      )
      .then((res) => {
        this.historyList = res.data;
        this.$store.commit("pageData/setdataCount", this.historyList.length);
      });
  },
  watch: {
    "$route.query.filter": function (newPage, oldPage) {
      if (newPage !== oldPage) {
        this.$http
          .get(
            `asterisk-api/history-call?filter_order_id=${this.$route.query.filter}`
          )
          .then((res) => {
            this.historyList = res.data;
            this.$store.commit(
              "pageData/setdataCount",
              this.historyList.length
            );
          });
      }
    },
  },
  methods: {
    call() {
      if (this.$store.state.caller.status) {
        let initiatorNumber = JSON.parse(
          localStorage.getItem("sipConfig")
        ).user;
        this.$http
          .post("/connect-driver-operator", {
            number: initiatorNumber,
            phone: "992" + this.selectedPhone,
          })
          .then(() => {
            this.$store.commit("caller/UPDATE_IS_INITIATOR_STATE", true);
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Запрос на соединение отправлен",
                icon: "XIcon",
                variant: "success",
                text: "",
              },
            });
          })
          .catch((err) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Ошибка",
                icon: "XIcon",
                variant: "danger",
                text: err,
              },
            });
          });
      } else {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Включите SIP телефонию",
            icon: "XIcon",
            variant: "danger",
            text: "",
          },
        });
      }
    },
    getAudio() {
      this.$bvModal.show("audio");
    },
    handleContextMenuEvent(item, index, event) {
      event.preventDefault();
      this.$refs.menu.open(event);
      this.selectedPhone = item.src;
      this.audioSrc = item.path_record;
    },
    getDrivers(data) {
      this.$router.push({
        name: "infoDriver",
        query: { filter: data.item.performer_id },
      });
      this.$store.commit("draggableTab/ADD_TAB", [
        "Водитель " + data.item.performer_id,
        `/infoDriver`,
        data.item.performer_id,
      ]);
    },
    getOrderHistory(data) {
      this.$store.commit("draggableTab/ADD_TAB", [
        "История " + data.item.order_id,
        `/order/order-history`,
        data.item.order_id,
      ]);
      this.$router.push({
        name: "orderHistory",
        query: { filter: data.item.order_id },
      });
    },
    clicked(item, index, event) {
      this.audioSrc = item.path_record;
      cellSelect();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/table.scss";

.my-card .card-body {
  padding: 0px;
}

.my-card {
  overflow: scroll;
  height: calc(100vh - 92px);
  @font-face {
    font-family: "Verdana";
    src: url("../../../assets/fonts/Verdana.ttf");
  }
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
}

@media (max-height: 700px) {
  .my-card {
    overflow: auto;
    height: 530px;
  }
}
</style>
